import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  LinearProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import api from "../../services/api";

const ImportCatalogModal = ({ open, onClose, companyId }) => {
  const [progress, setProgress] = useState(0);
  const [isImporting, setIsImporting] = useState(false);
  const [connections, setConnections] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState("");
  const [previewProducts, setPreviewProducts] = useState([]);

  const handleImport = async () => {
    if (!selectedConnection) {
      alert("Por favor, selecione uma conexão antes de importar.");
      return;
    }

    setIsImporting(true);
    setProgress(0);

    // Permitir renderização antes de continuar
    await new Promise((resolve) => setTimeout(resolve, 100));

    const interval = setInterval(() => {
      setProgress((prev) => {
        const next = prev + 10;
        return next >= 90 ? 90 : next; // Progresso inicial vai até 90%
      });
    }, 500);

    try {
      const response = await api.post("/api/catalog/import-whatsapp-catalog", {
        whatsappId: selectedConnection,
        companyId,
      });

      clearInterval(interval); // Finaliza o intervalo de progresso inicial
      setProgress(100); // Define o progresso como completo
      alert(response.data.message || "Catálogo importado com sucesso!");

      window.location.reload(); // Atualiza a página inteira
    } catch (error) {
      clearInterval(interval); // Certifique-se de limpar o intervalo
      console.error("Erro ao importar catálogo:", error);
      alert("Erro ao importar catálogo. Tente novamente.");
    } finally {
      setIsImporting(false);
    }
  };

  const fetchConnections = async () => {
    try {
      const response = await api.post("/api/catalog/import-whatsapp-catalog", {
        companyId,
      });

      if (response.data.connections && response.data.connections.length > 0) {
        setConnections(response.data.connections);

        // Se houver apenas uma conexão, não selecionar automaticamente
        if (response.data.connections.length === 1) {
          console.log(
            "[Fetch Conexões] Apenas uma conexão encontrada. Conexão:",
            response.data.connections[0]
          );
        } else {
          setSelectedConnection(response.data.connections[0]?.id || "");
        }
      } else {
        alert(response.data.message || "Nenhuma conexão encontrada.");
        onClose();
      }
    } catch (error) {
      console.error("Erro ao buscar conexões:", error);
      alert("Erro ao buscar conexões. Tente novamente.");
    }
  };

  const handlePreviewProducts = async () => {
    if (!selectedConnection) {
      alert("Por favor, selecione uma conexão antes de visualizar.");
      return;
    }

    try {
      const response = await api.post(
        "/api/catalog/import-whatsapp-catalog?preview=true",
        {
          whatsappId: selectedConnection,
          companyId,
        }
      );

      if (response.data.products) {
        setPreviewProducts(response.data.products);
        alert(
          "Produtos disponíveis para importação: " +
            response.data.products.map((p) => p.name).join(", ")
        );
      } else {
        alert("Nenhum produto disponível para pré-visualização.");
      }
    } catch (error) {
      console.error("Erro ao pré-visualizar produtos:", error);
      alert("Erro ao pré-visualizar produtos. Tente novamente.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Importar Catálogo</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Clique no botão abaixo para importar os produtos do catálogo.
        </Typography>

        {!isImporting && connections.length > 0 && (
          <>
            <Typography variant="body2" style={{ marginTop: "16px" }}>
              Escolha uma conexão do WhatsApp:
            </Typography>
            <Select
              value={selectedConnection}
              onChange={(e) => setSelectedConnection(e.target.value)}
              fullWidth
              style={{ marginTop: "8px" }}
            >
              {connections.map((conn) => (
                <MenuItem key={conn.id} value={conn.id}>
                  {conn.name || `Conexão ${conn.id}`}
                </MenuItem>
              ))}
            </Select>

            <div style={{ marginTop: "16px", display: "flex", gap: "8px" }}>
              <Button
                variant="contained"
                color="default"
                onClick={handlePreviewProducts}
                disabled={!selectedConnection}
              >
                Pré-visualizar produtos
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleImport}
                disabled={!selectedConnection}
              >
                Importar produtos do catálogo
              </Button>
            </div>
          </>
        )}

        {isImporting && (
          <>
            <Typography variant="body2" style={{ marginTop: "16px" }}>
              Progresso: {progress}%
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ marginTop: "8px" }}
            />
          </>
        )}

        {!isImporting && connections.length === 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={fetchConnections}
            style={{ marginTop: "16px" }}
          >
            Buscar Conexões
          </Button>
        )}

        {previewProducts.length > 0 && (
          <div style={{ marginTop: "16px" }}>
            <Typography variant="h6">Produtos Pré-visualizados:</Typography>
            <ul>
              {previewProducts.map((product, index) => (
                <li key={index}>{product.name}</li>
              ))}
            </ul>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{ backgroundColor: "red", color: "white" }}
          disabled={isImporting}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportCatalogModal;
