import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";

const ConfirmDialog = ({ open, onClose, onConfirm, message }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmação</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
      <Button
          onClick={onConfirm}
          style={{
            backgroundColor: "green",
            color: "white",
          }}
        >
          Confirmar
        </Button>
        <Button
          onClick={onClose}
          style={{
            backgroundColor: "red",
            color: "white",
          }}
        >
          Cancelar
        </Button>

      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
