import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Grid,
  CircularProgress,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ProdutoCard from "./ProdutoCard";
import ProdutoForm from "./ProdutoForm";
import ConfirmDialog from "./ConfirmDialog";
import { Pagination } from "@material-ui/lab";
import * as XLSX from "xlsx";
import { LinearProgress } from "@material-ui/core";
import ImportCatalogModal from "./ImportCatalogModal";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  searchBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  loading: {
    textAlign: "center",
    margin: theme.spacing(3),
  },
  noData: {
    textAlign: "center",
    margin: theme.spacing(3),
  },
  paginationInfo: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  productGrid: {
    marginBottom: theme.spacing(3),
  },
}));

const Produtos = () => {
  const classes = useStyles();

  const [produtos, setProdutos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [openApiKeyDialog, setOpenApiKeyDialog] = useState(false);
  const [apiToken, setApiToken] = useState("");
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [canAddProduct, setCanAddProduct] = useState(true);
  const [productLimitInfo, setProductLimitInfo] = useState({ limit: 0, used: 0 });
  const fileInputRef = useRef();
  const [companyId, setCompanyId] = useState(null);
  const [authToken, setAuthToken] = useState("");
  const [openCatalogImport, setOpenCatalogImport] = useState(false);



  useEffect(() => {
    fetchProdutos();
  }, [searchTerm, currentPage]);

  useEffect(() => {
    fetchApiToken();
    checkProductLimit();
  }, []);

  const fetchProdutos = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/produtos", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          search: searchTerm,
          page: currentPage,
          order: "name",
          limit: 18,
        },
      });
      setProdutos(data.produtos);
      setTotalProducts(data.total);
      setTotalPages(Math.ceil(data.total / 18));
    } catch (err) {
      toastError(err.response?.data?.error || "Erro ao carregar produtos.");
    } finally {
      setLoading(false);
    }
  };
  const decodeJwt = (token) => {
    try {
      const payloadBase64 = token.split(".")[1]; // O payload está na segunda parte do token
      const decodedPayload = JSON.parse(atob(payloadBase64)); // Decodifica o base64
      return decodedPayload;
    } catch (err) {
      console.error("Erro ao decodificar o token:", err);
      return null;
    }
  };
  
  const fetchApiToken = () => {
    try {
      const authToken = localStorage.getItem("token"); // Token de autenticação
      if (!authToken) {
        setApiToken("Nenhum token encontrado.");
        setCompanyId("Não encontrado");
        return;
      }
  
      const decodedToken = decodeJwt(authToken); // Decodifica o token de autenticação
      const companyId = decodedToken?.companyId; // Extrai o companyId
  
      setApiToken(""); // Inicialmente, o token de API é vazio
      setCompanyId(companyId || "Não encontrado"); // Atualiza o companyId
  
      // Adicione o token de autenticação no estado para exibição
      setAuthToken(authToken);
    } catch (err) {
      console.error("Erro ao buscar token ou companyId:", err);
      setApiToken("Erro ao buscar token.");
      setCompanyId("Erro ao buscar companyId.");
    }
  };
  
  
  const handleGenerateToken = async () => {
    try {
      const { data } = await api.post("/produtos/token", null, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setApiToken(data.token); // Token de API gerado
      alert("Token atualizado com sucesso: " + data.token);
    } catch (err) {
      console.error("Erro ao gerar token:", err);
      alert("Erro ao gerar token.");
    }
  };
  
  const handleCatalogImport = async () => {
    try {
      const response = await api.post(
        "/api/catalog/import-whatsapp-catalog",
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
  
      alert(response.data.message || "Catálogo importado com sucesso!");
      setOpenCatalogImport(false);
      fetchProdutos(); // Atualiza a lista de produtos
    } catch (err) {
      console.error("Erro ao importar catálogo:", err);
      alert("Erro ao importar catálogo. Tente novamente.");
    }
  };
  
  

  const checkProductLimit = async () => {
    try {
      const { data } = await api.get("/limitproduct");
      const { limit, used, canAddProduct } = data;
  
      // Desativa os botões se `limit` for 0, `used` for maior ou igual a `limit`,
      // ou se `canAddProduct` for explicitamente falso
      if (!limit || used >= limit || !canAddProduct) {
        setCanAddProduct(false);
      } else {
        setCanAddProduct(true);
      }
  
      setProductLimitInfo({ limit: limit || 0, used: used || 0 }); // Valores padrão para evitar erros
    } catch (err) {
      console.error("Erro ao verificar limite de produtos:", err);
      toastError("Erro ao verificar limite de produtos.");
  
      // Bloqueia os botões em caso de erro
      setCanAddProduct(false);
      setProductLimitInfo({ limit: 0, used: 0 });
    }
  };
  
  

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/produtos/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setProdutos(produtos.filter((produto) => produto.id !== id));
      setOpenDeleteDialog(false);
      checkProductLimit(); // Atualiza o limite após deletar
    } catch (err) {
      toastError(err.response?.data?.error || "Erro ao deletar produto.");
    }
  };

  const handleEdit = (produto) => {
    setSelectedProduct(produto);
    setOpenForm(true);
  };

  const handleAdd = () => {
    if (!canAddProduct) {
      alert("Você atingiu o limite de produtos do seu plano.");
      return;
    }
    setSelectedProduct(null);
    setOpenForm(true);
  };

  const handleOpenApiKeyDialog = () => {
    setOpenApiKeyDialog(true);
  };

  const handleCloseApiKeyDialog = () => {
    setOpenApiKeyDialog(false);
  };


  const handleImportDialogOpen = () => {
    setOpenImportDialog(true);
  };

  const handleImportDialogClose = () => {
    setOpenImportDialog(false);
  };

  const handleFileUpload = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) {
      alert("Por favor, selecione um arquivo.");
      return;
    }

    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(sheet);

      for (const row of rows) {
        const payload = {
          sku: row.sku,
          name: row.name,
          price: row.price,
          price2: row.price2,
          quantidade: row.quantidade,
          message: row.message,
        };

        await api.post("/produtos", payload, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      }

      alert("Produtos importados com sucesso.");
      fetchProdutos();
      setOpenImportDialog(false);
    } catch (err) {
      console.error("Erro ao importar produtos:", err);
      alert("Erro ao importar produtos. Verifique o arquivo e tente novamente.");
    }
  };

  const handleDownloadModelo = () => {
    const modeloData = [
      { sku: "12345", name: "Produto Exemplo", price: "100.00", price2: "100.00", quantidade: "10", message: "Descrição" },
    ];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(modeloData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Modelo");
    XLSX.writeFile(workbook, "modelo-de-importacao-produtos.xlsx");
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchBarContainer}>
        <TextField
          style={{ width: "40%" }}
          label="Pesquisar"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") fetchProdutos();
          }}
        />

<div style={{ display: "flex", alignItems: "center", marginLeft: "16px", flexDirection: "column" }}>
  {productLimitInfo ? (
    <>
      <Typography
        variant="h6"
        style={{
          fontWeight: "bold",
          color: canAddProduct ? "green" : "red",
        }}
      >
        {canAddProduct ? "Dentro do Limite" : "Limite Atingido"}
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Typography variant="body2">Usados: {productLimitInfo.used}</Typography>
        <Typography variant="body2">Limite: {productLimitInfo.limit}</Typography>
      </div>
      <div style={{ width: "100%", maxWidth: "300px" }}>
        <LinearProgress
          variant="determinate"
          value={(productLimitInfo.used / productLimitInfo.limit) * 100}
          style={{
            height: 10,
            borderRadius: 5,
            backgroundColor: "#f0f0f0",
          }}
        />
      </div>
    </>
  ) : (
    <Typography
      variant="body2"
      style={{
        color: "gray",
      }}
    >
      Carregando...
    </Typography>
  )}
</div>


<div style={{ display: "flex", alignItems: "center" }}>
  <Button
    variant="contained"
    color="primary"
    onClick={handleAdd}
    style={{
      marginLeft: "8px",
      padding: "4px 8px", // Ajusta o tamanho do botão
      fontSize: "14px", // Ajusta o tamanho do texto
      transform: "translate(0, 0)", // Mova o botão para cima/baixo/direita/esquerda
    }}
    disabled={!canAddProduct} // Desativa o botão se o limite for atingido
  >
    Cadastrar Produto
  </Button>
  <Button
  variant="contained"
  color="default"
  onClick={() => setOpenCatalogImport(true)}
  style={{
    marginLeft: "8px",
    padding: "4px 8px", // Ajusta o tamanho do botão
    fontSize: "14px", // Ajusta o tamanho do texto
    transform: "translate(0, 0)", // Mova o botão para cima/baixo/direita/esquerda
  }}
  disabled={!canAddProduct} // Desativa o botão se o limite for atingido
>
  Importar Catálogo
</Button>


  <Button
    variant="contained"
    color="secondary"
    onClick={handleImportDialogOpen}
    style={{
      marginLeft: "8px",
      padding: "4px 8px", // Ajusta o tamanho do botão
      fontSize: "14px", // Ajusta o tamanho do texto
      transform: "translate(0, 0)", // Mova o botão para cima/baixo/direita/esquerda
    }}
    disabled={!canAddProduct} // Desativa o botão se o limite for atingido
  >
    Importar Produtos
  </Button>
  <Tooltip title={!canAddProduct ? "Limite de produtos atingido" : "Gerar chave da API"}>
    <IconButton
      onClick={handleOpenApiKeyDialog}
      style={{ marginLeft: "8px" }}
      disabled={!canAddProduct} // Desativa o botão se o limite for atingido
    >
      <SettingsIcon />
    </IconButton>
  </Tooltip>
</div>

      </div>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : produtos.length === 0 ? (
        <div className={classes.noData}>
          <Typography variant="h6">Nenhum produto encontrado.</Typography>
        </div>
      ) : (
        <>
          <Grid container spacing={3} className={classes.productGrid}>
            {produtos.map((produto) => (
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={produto.id}>
                <ProdutoCard
                  produto={produto}
                  onEdit={handleEdit}
                  onDelete={() => setOpenDeleteDialog(produto.id)}
                />
              </Grid>
            ))}
          </Grid>
          <div className={classes.paginationInfo}>
            <Typography variant="body2">
              Exibindo {Math.min((currentPage - 1) * 18 + 1, totalProducts)} -{" "}
              {Math.min(currentPage * 18, totalProducts)} de {totalProducts} produtos
            </Typography>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            />
          </div>
        </>
      )}
      <ProdutoForm
        open={openForm}
        onClose={() => setOpenForm(false)}
        produto={selectedProduct}
        onRefresh={() => {
          fetchProdutos();
          checkProductLimit();
        }}
      />
      <ImportCatalogModal
  open={openCatalogImport}
  onClose={() => setOpenCatalogImport(false)}
  onImport={handleCatalogImport}
/>

      <ConfirmDialog
        open={!!openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={() => handleDelete(openDeleteDialog)}
        message="Você tem certeza que deseja deletar este produto?"
      />
      <Dialog open={openApiKeyDialog} onClose={handleCloseApiKeyDialog} maxWidth="md" fullWidth>
  <DialogTitle>Gerar Chave da API</DialogTitle>
  <DialogContent>
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
      {/* Coluna da esquerda - botão e token */}
      <div style={{ flex: 1, marginRight: "16px" }}>
  <Typography variant="body1">
    Token atual:
    <strong>
      {apiToken ? (
        <span style={{ wordBreak: "break-word" }}> {apiToken}</span>
      ) : (
        " Nenhum token gerado."
      )}
    </strong>
  </Typography>
  <Button
    variant="contained"
    color="primary"
    onClick={handleGenerateToken}
    style={{ marginTop: "16px" }}
  >
    Gerar Novo Token
  </Button>
</div>


      {/* Coluna da direita - instruções */}
      <div style={{ flex: 2, paddingLeft: "16px", borderLeft: "1px solid #ccc" }}>
        <Typography variant="h6" gutterBottom>
          Como gerar e usar o token
        </Typography>
        <Typography variant="body2" paragraph>
          1. Clique no botão <strong>"Gerar Novo Token"</strong> para criar uma nova chave de API.
        </Typography>
        <Typography variant="body2" paragraph>
          2. Copie o token gerado. Este será exibido no campo acima.
        </Typography>
        <Typography variant="body2" paragraph>
          3. Use o token em suas requisições API incluindo-o no cabeçalho das requisições como:
        </Typography>
        <Typography
          variant="body2"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "8px",
            borderRadius: "4px",
            fontFamily: "monospace",
          }}
        >
          Authorization: Bearer &lt;seu_token&gt;
        </Typography>
        <Typography
          variant="body2"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "8px",
            borderRadius: "4px",
            fontFamily: "monospace",
          }}
        >
          "sku": "10370",
        </Typography>
        <Typography
          variant="body2"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "8px",
            borderRadius: "4px",
            fontFamily: "monospace",
          }}
        >
          "name": "Monitor Gamer",
        </Typography>
        <Typography
          variant="body2"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "8px",
            borderRadius: "4px",
            fontFamily: "monospace",
          }}
        >
          "price": 99.50,
        </Typography>
        <Typography
          variant="body2"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "8px",
            borderRadius: "4px",
            fontFamily: "monospace",
          }}
        >
          "price2": 10.50,
        </Typography>
        <Typography
  variant="body2"
  style={{
    backgroundColor: "#f4f4f4",
    padding: "8px",
    borderRadius: "4px",
    fontFamily: "monospace",
  }}
>
  "companyId": {companyId},
</Typography>

        <Typography
          variant="body2"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "8px",
            borderRadius: "4px",
            fontFamily: "monospace",
          }}
        >
          "message": "Monitor Gamer 144Hz"
        </Typography>
      </div>
    </div>
  </DialogContent>
  <DialogActions>
    <Button
      onClick={handleCloseApiKeyDialog}
      style={{ backgroundColor: "red", color: "white" }}
    >
      Fechar
    </Button>
  </DialogActions>
</Dialog>

      <Dialog open={openImportDialog} onClose={handleImportDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>Importar Produtos</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Selecione um arquivo Excel (.xls ou .xlsx) para importar produtos.
          </Typography>
          <input
            type="file"
            accept=".xls,.xlsx"
            ref={fileInputRef}
            style={{ marginTop: "16px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadModelo}
            style={{ marginTop: "16px" }}
          >
            Baixar Modelo de Importação
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleFileUpload}
            style={{ backgroundColor: "green", color: "white" }}
          >
            Importar
          </Button>
          <Button
            onClick={handleImportDialogClose}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Produtos;
