import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import api from "../../services/api";

const useStyles = makeStyles({
  buttonEdit: {
    border: "1px solid #1976d2",
    color: "#1976d2",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#e3f2fd",
    },
  },
  buttonDelete: {
    border: "1px solid #d32f2f",
    color: "#d32f2f",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#ffebee",
    },
  },
  card: {
    borderRadius: "8px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    position: "relative",
    transition: "opacity 0.3s",
  },
  imageBox: {
    height: 140,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px 8px 0 0",
    position: "relative",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  arrowButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    zIndex: 1,
    height: 40,
    width: 40,
  },
  arrowLeft: {
    left: "10px",
  },
  arrowRight: {
    right: "10px",
  },
  productName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333",
  },
  skuText: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#555",
  },
  messageText: {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#666",
  },
  outOfStock: {
    opacity: 0.5,
  },
});

const ProdutoCard = ({ produto, onEdit, onDelete }) => {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.get(`/produtos/${produto.id}/images`);
        setImages(response.data.map((img) => img.imageUrl));
      } catch (err) {
        console.error("Erro ao buscar imagens:", err);
        setImages([]);
      }
    };

    fetchImages();
  }, [produto.id]);

  const handleNavigation = (direction) => {
    setCurrentImageIndex((prevIndex) => {
      if (direction === "next") {
        return (prevIndex + 1) % images.length;
      }
      if (direction === "prev") {
        return (prevIndex - 1 + images.length) % images.length;
      }
      return prevIndex;
    });
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "Sem descrição";
    const cleanText = text.replace(/\n/g, " ");
    return cleanText.length > maxLength ? cleanText.slice(0, maxLength) + "..." : cleanText;
  };

  return (
    <Card
    className={`${classes.card} ${
      !produto.quantidade || produto.quantidade === "0" ? classes.outOfStock : ""
    }`}
    >
      <div className={classes.imageBox}>
        <img
          src={images[currentImageIndex] || "https://via.placeholder.com/150"}
          alt={produto.name}
          className={classes.image}
        />
        {images.length > 1 && (
          <>
            <IconButton
              className={`${classes.arrowButton} ${classes.arrowLeft}`}
              onClick={() => handleNavigation("prev")}
            >
              <ArrowBackIos />
            </IconButton>
            <IconButton
              className={`${classes.arrowButton} ${classes.arrowRight}`}
              onClick={() => handleNavigation("next")}
            >
              <ArrowForwardIos />
            </IconButton>
          </>
        )}
      </div>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="h2"
          className={classes.productName}
        >
          {truncateText(produto.name, 25)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.skuText}
        >
          SKU: {produto.sku}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={classes.messageText}
        >
          {truncateText(produto.message, 54)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          className={classes.buttonEdit}
          onClick={() => onEdit(produto)}
        >
          Editar
        </Button>
        <Button
          size="small"
          className={classes.buttonDelete}
          onClick={onDelete}
        >
          Deletar
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProdutoCard;
