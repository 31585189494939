import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, Delete } from "@material-ui/icons";
import api from "../../services/api";
import { InputAdornment } from "@material-ui/core";

const ProdutoForm = ({ open, onClose, produto, onRefresh }) => {
  const initialFormData = {
    sku: "",
    name: "",
    message: "",
    price: "",
    quantidade: "",
    price2: "", // Novo campo para "Preço Parcelado"
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    const numberValue = parseFloat(value.replace(",", "."));
    return numberValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  

  const [formData, setFormData] = useState(initialFormData);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [existingImages, setExistingImages] = useState([]);

  useEffect(() => {
    if (produto) {
      setFormData({
        ...produto,
        price2: produto.price2 || "", // Certifica-se de carregar o "Preço Parcelado"
      });

      const fetchImages = async () => {
        try {
          const response = await api.get(`/produtos/${produto.id}/images`);
          const images = response.data;

          setExistingImages(images);
          setImagePreviews(images.map((img) => img.imageUrl));
        } catch (err) {
          console.error("Erro ao buscar imagens:", err);
        }
      };

      fetchImages();
    } else {
      setFormData(initialFormData);
      setSelectedImages([]);
      setImagePreviews([]);
      setExistingImages([]);
    }
  }, [produto]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "sku") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Remove caracteres não numéricos
      setFormData((prevData) => ({ ...prevData, [name]: numericValue }));
      return;
    }
  
    if (name === "price" || name === "price2") {
      // Remove caracteres inválidos e mantém ponto e vírgula
      const formattedValue = value.replace(/[^0-9.,]/g, "").replace(/\./g, "").replace(",", ".");
      setFormData((prevData) => ({ ...prevData, [name]: formattedValue }));
      return;
    }
  
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  
  

  const handleImageChange = (file) => {
    if (!file) return;

    const updatedImages = [...selectedImages, file];
    setSelectedImages(updatedImages);

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreviews((prev) => [...prev, reader.result]);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = (index, isExistingImage = false) => {
    if (isExistingImage) {
      const imageId = existingImages[index].id;
      api
        .delete("/produtos/delete-image", { data: { imageId } })
        .then(() => {
          setExistingImages((prev) => prev.filter((_, i) => i !== index));
          setImagePreviews((prev) => prev.filter((_, i) => i !== index));
          setCurrentImageIndex(0); // Reinicia o índice
        })
        .catch((err) => {
          console.error("Erro ao deletar imagem:", err);
          alert("Erro ao deletar imagem. Tente novamente.");
        });
    } else {
      const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
      const updatedImages = selectedImages.filter((_, i) => i !== index);

      setImagePreviews(updatedPreviews);
      setSelectedImages(updatedImages);
      setCurrentImageIndex(0); // Reinicia o índice
    }
  };

  const handleNavigation = (direction) => {
    setCurrentImageIndex((prevIndex) => {
      if (direction === "next") {
        return (prevIndex + 1) % imagePreviews.length;
      }
      if (direction === "prev") {
        return (prevIndex - 1 + imagePreviews.length) % imagePreviews.length;
      }
      return prevIndex;
    });
  };

  const handleSubmit = async () => {
    try {
      if (!formData.sku.trim() || !formData.name.trim()) {
        alert("Os campos SKU e Nome são obrigatórios.");
        return;
      }

      let produtoId = produto?.id;

      if (!produtoId) {
        const response = await api.post("/produtos", formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        produtoId = response.data.id;
      } else {
        await api.put(`/produtos/${produtoId}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      }

      if (selectedImages.length > 0) {
        const formDataImages = new FormData();
        selectedImages.forEach((image) => {
          formDataImages.append("images", image);
        });
        formDataImages.append("produtoId", produtoId);

        await api.post("/produtos/upload", formDataImages, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      }

      onRefresh();
      setFormData(initialFormData);
      setSelectedImages([]);
      setImagePreviews([]);
      setExistingImages([]);
      onClose();
    } catch (err) {
      console.error("Erro ao salvar produto:", err);
      alert("Erro ao salvar produto. Verifique os dados e tente novamente.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{produto ? "Editar Produto" : "Cadastrar Produto"}</DialogTitle>
      <DialogContent>
        <div
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            border: "2px solid #ccc",
            borderRadius: "8px",
            marginBottom: "16px",
          }}
        >
          {imagePreviews.length > 0 ? (
            <>
              <img
                src={imagePreviews[currentImageIndex]}
                alt={`Imagem ${currentImageIndex}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
              {imagePreviews.length > 1 && (
                <>
                  <IconButton
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    onClick={() => handleNavigation("prev")}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <IconButton
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    onClick={() => handleNavigation("next")}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </>
              )}
            </>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Nenhuma imagem carregada
            </Typography>
          )}
        </div>

        <div style={{ display: "flex", gap: "10px", marginBottom: "16px" }}>
          {existingImages.map((img, index) => (
            <div
              key={`existing-${index}`}
              style={{
                position: "relative",
                width: "100px",
                height: "100px",
                border: "2px solid #ccc",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <img
                src={img.imageUrl}
                alt={`Imagem existente ${index}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <IconButton
                onClick={() => handleRemoveImage(index, true)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <Delete />
              </IconButton>
            </div>
          ))}
          {selectedImages.map((_, index) => (
            <div
              key={`new-${index}`}
              style={{
                position: "relative",
                width: "100px",
                height: "100px",
                border: "2px dashed #ccc",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <img
                src={imagePreviews[existingImages.length + index]}
                alt={`Imagem nova ${index}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <IconButton
                onClick={() => handleRemoveImage(existingImages.length + index)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <Delete />
              </IconButton>
            </div>
          ))}
        </div>

        <label htmlFor="upload-image">
  <input
    type="file"
    id="upload-image"
    style={{ display: "none" }}
    onChange={(e) => handleImageChange(e.target.files[0])}
  />
  <Button
    variant="contained"
    color="primary"
    component="span"
    size="small" // Tamanho menor do botão
    style={{ marginTop: "10px" }}
  >
    Adicionar Imagem
  </Button>
</label>


        <TextField
          label="SKU"
          name="sku"
          value={formData.sku}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Nome"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Descrição"
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        {/* Campo de Preço à Vista */}
        <TextField
  label="Preço à Vista"
  name="price"
  value={formatCurrency(formData.price)}
  onChange={handleInputChange}
  fullWidth
  margin="normal"
  InputProps={{
    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
    inputProps: { min: 0 },
  }}
/>
        {/* Campo de Preço Parcelado */}
        <TextField
  label="12x de"
  name="price2"
  value={formatCurrency(formData.price2)}
  onChange={handleInputChange}
  fullWidth
  margin="normal"
  InputProps={{
    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
    inputProps: { min: 0 },
  }}
/>


        <TextField
          label="Quantidade"
          name="quantidade"
          type="number"
          value={formData.quantidade}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputProps={{ inputProps: { min: 0 } }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          style={{
            backgroundColor: "green",
            color: "white",
          }}
        >
          Salvar
        </Button>
        <Button
          onClick={onClose}
          style={{
            backgroundColor: "red",
            color: "white",
          }}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProdutoForm;
