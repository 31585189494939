import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert"; // Para exibir mensagens
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  input: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

const Pagamentos = () => {
  const classes = useStyles();

  // Estado para MercadoPago
  const [mercadoPagoStatus, setMercadoPagoStatus] = useState("Desativar");
  const [mercadoPagoPublicKey, setMercadoPagoPublicKey] = useState("");
  const [mercadoPagoAccessToken, setMercadoPagoAccessToken] = useState("");

  // Estado para GerenciaNet
  const [gerenciaNetStatus, setGerenciaNetStatus] = useState("Desativar");
  const [gerenciaNetPublicKey, setGerenciaNetPublicKey] = useState("");
  const [gerenciaNetAccessToken, setGerenciaNetAccessToken] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carregar dados do banco de dados ao montar o componente
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Carregar MercadoPago
        const mercadoPagoResponse = await api.get("/apiKeys?name=MercadoPago");
        if (mercadoPagoResponse.data.length > 0) {
          const mercadoPagoApiKey = mercadoPagoResponse.data[0];
          setMercadoPagoPublicKey(mercadoPagoApiKey.publicKey || "");
          setMercadoPagoAccessToken(mercadoPagoApiKey.accessToken || "");
          setMercadoPagoStatus(mercadoPagoApiKey.activated ? "Ativar" : "Desativar");
        }

        // Carregar GerenciaNet
        const gerenciaNetResponse = await api.get("/apiKeys?name=GerenciaNet");
        if (gerenciaNetResponse.data.length > 0) {
          const gerenciaNetApiKey = gerenciaNetResponse.data[0];
          setGerenciaNetPublicKey(gerenciaNetApiKey.publicKey || "");
          setGerenciaNetAccessToken(gerenciaNetApiKey.accessToken || "");
          setGerenciaNetStatus(gerenciaNetApiKey.activated ? "Ativar" : "Desativar");
        }
      } catch (err) {
        console.error("Erro ao carregar os dados", err);
      }
    };

    fetchData();
  }, []);

  // Função para salvar dados de MercadoPago
  const handleSaveMercadoPago = async () => {
    try {
      const activated = mercadoPagoStatus === "Ativar";
      await api.post("/apiKeys", {
        name: "MercadoPago",
        publicKey: mercadoPagoPublicKey,
        accessToken: mercadoPagoAccessToken,
        activated,
      });
      setShowSnackbar(true);
    } catch (err) {
      console.error("Erro ao salvar as chaves do MercadoPago", err);
    }
  };

  // Função para salvar dados de GerenciaNet
  const handleSaveGerenciaNet = async () => {
    try {
      const activated = gerenciaNetStatus === "Ativar";
      await api.post("/apiKeys", {
        name: "GerenciaNet",
        publicKey: gerenciaNetPublicKey,
        accessToken: gerenciaNetAccessToken,
        activated,
      });
      setShowSnackbar(true);
    } catch (err) {
      console.error("Erro ao salvar as chaves do GerenciaNet", err);
    }
  };

  // Fechar o popup
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" gutterBottom>
        Configuração de Pagamentos
      </Typography>
  
      {/* Layout Flexível para Configurações e Informações */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
        {/* Configurações de Pagamento */}
        <div style={{ flex: 2, marginRight: "16px" }}>
          {/* Seção para MercadoPago */}
          <Typography variant="h6">MercadoPago</Typography>
          <FormControl className={classes.formControl}>
            <InputLabel>Status</InputLabel>
            <Select
              value={mercadoPagoStatus}
              onChange={(e) => setMercadoPagoStatus(e.target.value)}
            >
              <MenuItem value="Ativar">Ativar</MenuItem>
              <MenuItem value="Desativar">Desativar</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Public Key"
            variant="outlined"
            className={classes.input}
            value={mercadoPagoPublicKey}
            onChange={(e) => setMercadoPagoPublicKey(e.target.value)}
          />
          <TextField
            label="Access Token"
            variant="outlined"
            className={classes.input}
            value={mercadoPagoAccessToken}
            onChange={(e) => setMercadoPagoAccessToken(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleSaveMercadoPago}>
            Salvar
          </Button>
  
          {/* Seção para GerenciaNet */}
          <Typography variant="h6" style={{ marginTop: "24px" }}>GerenciaNet</Typography>
          <FormControl className={classes.formControl}>
            <InputLabel>Status</InputLabel>
            <Select
              value={gerenciaNetStatus}
              onChange={(e) => setGerenciaNetStatus(e.target.value)}
            >
              <MenuItem value="Ativar">Ativar</MenuItem>
              <MenuItem value="Desativar">Desativar</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Public Key"
            variant="outlined"
            className={classes.input}
            value={gerenciaNetPublicKey}
            onChange={(e) => setGerenciaNetPublicKey(e.target.value)}
          />
          <TextField
            label="Access Token"
            variant="outlined"
            className={classes.input}
            value={gerenciaNetAccessToken}
            onChange={(e) => setGerenciaNetAccessToken(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleSaveGerenciaNet}>
            Salvar
          </Button>
        </div>
  
        {/* Informações ao lado direito */}
        <div style={{ flex: 1, padding: "16px", backgroundColor: "#f9f9f9", borderRadius: "8px", border: "1px solid #ddd" }}>
          <Typography variant="h6" gutterBottom>
            Informações
          </Typography>
          <Typography variant="body2">
          Diariamente, às <strong>7h</strong>, é realizada uma verificação automática que:
          </Typography>
          <Typography variant="body2" component="ul" style={{ marginTop: "8px", paddingLeft: "16px" }}>
            <li>Identifica empresas com planos que vencem em 7 dias. </li>
            <li>Gera automaticamente um link de pagamento.</li>
          </Typography>
          <Typography variant="body2" style={{ marginTop: "16px" }}>
          Atenção: Verifique se suas configurações estão devidamente ajustadas para garantir o funcionamento correto do processo.
          </Typography>
        </div>
      </div>
  
      {/* Popup para feedback */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Dados salvos com sucesso!
        </Alert>
      </Snackbar>
    </Container>
  );
  
};

export default Pagamentos;
