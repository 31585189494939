import React, { useEffect, useState, useContext } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api"; // Atualize o caminho
import { AuthContext } from "../../context/Auth/AuthContext"; // Atualize o caminho

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: "0 auto",
    maxWidth: "600px",
  },
  field: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  statusPaid: {
    color: "green",
  },
  statusPending: {
    color: "orange",
  },
  statusExpired: {
    color: "red",
  },
  table: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    borderRadius: "8px",
  },
}));

const GenerateInvoice = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [companies, setCompanies] = useState([]);
  const [invoices, setInvoices] = useState([]); // Estado para armazenar as faturas
  const [formData, setFormData] = useState({
    companyId: "",
    dueDate: "",
    detail: "",
    status: "pendente",
    value: "",
    users: "",
    queues: 0,
    useWhatsapp: true,
    useFacebook: true,
    useInstagram: true,
    useCampaigns: true,
    useSchedules: true,
    useInternalChat: true,
    useExternalApi: true,
    createdAt: "",
    updatedAt: "",
    linkInvoice: "",
  });
  const [editData, setEditData] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);

  // Trava para redirecionar usuários não autorizados
  useEffect(() => {
    if (!user?.super) {
      toast.error(
        "Você não tem permissão para acessar esta página. Redirecionando..."
      );
      setTimeout(() => {
        history.push("/");
      }, 10);
    }
  }, [user, history]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const { data } = await api.get("/companies");

        // Verifica se `data` é um array ou precisa acessar `data.companies`
        if (Array.isArray(data)) {
          setCompanies(data);
        } else if (data.companies && Array.isArray(data.companies)) {
          setCompanies(data.companies); // Caso a API retorne em um objeto `companies`
        } else {
          console.error("Formato inesperado da resposta da API:", data);
          setCompanies([]);
        }
      } catch (error) {
        console.error("Erro ao buscar empresas:", error);
        setCompanies([]);
      }
    };

    const fetchInvoices = async () => {
      try {
        const { data } = await api.get("/public/invoices"); // Use a nova API pública
        setInvoices(data || []);
      } catch (error) {
        console.error("Erro ao buscar faturas:", error);
        setInvoices([]);
      }
    };

    fetchCompanies();
    fetchInvoices();
  }, []);

  const handleEditClick = (invoice) => {
    setCurrentInvoice(invoice);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentInvoice(null);
  };


  const handleModalSave = async () => {
    try {
      console.log("Enviando dados para atualização:", currentInvoice); // Verificar dados enviados
      await api.put(`/invoices/${currentInvoice.id}`, currentInvoice);
      toast.success("Fatura atualizada com sucesso!");
      const { data } = await api.get("/public/invoices"); // Atualiza a lista de faturas
      setInvoices(data || []);
      handleModalClose();
    } catch (error) {
      console.error("Erro ao salvar fatura:", error); // Verificar erros
      toast.error("Erro ao atualizar fatura.");
    }
  };
  
  
const handleModalChange = (e) => {
  const { name, value } = e.target;
  setCurrentInvoice({ ...currentInvoice, [name]: value });
};

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Adiciona a data atual
    const now = new Date().toISOString().replace("T", " ").split(".")[0] + ".000 -0300";
    formData.createdAt = now;
    formData.updatedAt = now;

    if (!formData.linkInvoice) {
      formData.linkInvoice = "https://";
    }

    try {
      await api.post("/invoices", formData);
      alert("Fatura gerada com sucesso!");
      setFormData({
        companyId: "",
        dueDate: "",
        detail: "",
        status: "pendente",
        value: "",
        users: "",
        queues: 0,
        useWhatsapp: true,
        useFacebook: true,
        useInstagram: true,
        useCampaigns: true,
        useSchedules: true,
        useInternalChat: true,
        useExternalApi: true,
        createdAt: "",
        updatedAt: "",
        linkInvoice: "",
      });
      const { data } = await api.get("/public/invoices"); // Atualiza a lista de faturas após a criação
      setInvoices(data || []);
    } catch (error) {
      console.error("Erro ao gerar fatura:", error);
      alert("Erro ao gerar fatura.");
    }
  };
  const handleDelete = async (id) => {
    if (window.confirm("Tem certeza de que deseja deletar esta fatura?")) {
      try {
        await api.delete(`/invoices/${id}`); // Substitua pelo endpoint correto da sua API
        toast.success("Fatura deletada com sucesso!");
        
        // Atualize a lista de faturas após a exclusão
        const { data } = await api.get("/public/invoices");
        setInvoices(data || []);
      } catch (error) {
        console.error("Erro ao deletar fatura:", error);
        toast.error("Erro ao deletar fatura.");
      }
    }
  };
 

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Gerar Fatura
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Company ID */}
        <FormControl className={classes.field} required>
          <InputLabel>Empresa</InputLabel>
          <Select
            name="companyId"
            value={formData.companyId}
            onChange={handleInputChange}
          >
            {Array.isArray(companies) && companies.length > 0 ? (
              companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Nenhuma empresa encontrada</MenuItem>
            )}
          </Select>
          <FormHelperText>Selecione uma empresa</FormHelperText>
        </FormControl>

        {/* Due Date */}
        <TextField
          label="Data de Vencimento"
          type="datetime-local"
          name="dueDate"
          value={formData.dueDate}
          onChange={handleInputChange}
          className={classes.field}
          InputLabelProps={{ shrink: true }}
          required
        />

        {/* Detail */}
        <TextField
          label="Nome do Plano"
          name="detail"
          value={formData.detail}
          onChange={handleInputChange}
          className={classes.field}
          required
        />

        {/* Status */}
        <FormControl className={classes.field} required>
          <InputLabel>Status</InputLabel>
          <Select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
          >
            <MenuItem value="Pago">
              <span className={classes.statusPaid}>Pago</span>
            </MenuItem>
            <MenuItem value="Pendente">
              <span className={classes.statusPending}>Pendente</span>
            </MenuItem>
            <MenuItem value="Vencido">
              <span className={classes.statusExpired}>Vencido</span>
            </MenuItem>
          </Select>
          <FormHelperText>Selecione o status</FormHelperText>
        </FormControl>

        {/* Value */}
        <TextField
          label="Valor (R$)"
          type="number"
          step="0.01"
          name="value"
          value={formData.value}
          onChange={handleInputChange}
          className={classes.field}
          required
        />

        {/* Users */}
        <TextField
          label="Quantidade de Usuários"
          type="number"
          name="users"
          value={formData.users}
          onChange={handleInputChange}
          className={classes.field}
          required
        />
        {/* Conexões */}
        <TextField
          label="Número  de Conexões"
          type="number"
          name="connections"
          value={formData.connections}
          onChange={handleInputChange}
          className={classes.field}
          required
        />


        {/* Link Invoice */}
        <TextField
          label="Link da Fatura"
          name="linkInvoice"
          value={formData.linkInvoice}
          onChange={handleInputChange}
          className={classes.field}
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Gerar Fatura
        </Button>
      </form>

      {/* Histórico de Faturas */}
      <Typography variant="h6" gutterBottom className={classes.table}>
        Histórico de Faturas
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Empresa</TableCell>
            <TableCell>Vencimento</TableCell>
            <TableCell>Detalhes</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Valor (R$)</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.id}>
              <TableCell>{invoice.company ? invoice.company.name : "Empresa não encontrada"}</TableCell>
              <TableCell>{invoice.dueDate}</TableCell>
              <TableCell>{invoice.detail}</TableCell>
              <TableCell>
                {invoice.status === "Pago" && (
                  <span className={classes.statusPaid}>Pago</span>
                )}
                {invoice.status === "Pendente" && (
                  <span className={classes.statusPending}>Pendente</span>
                )}
                {invoice.status === "Vencido" && (
                  <span className={classes.statusExpired}>Vencido</span>
                )}
              </TableCell>
              <TableCell>{invoice.value}</TableCell>
              <TableCell>
              <Button
  variant="contained"
  color="primary"
  onClick={() => handleEditClick(invoice)}
  style={{
    width: "50px", // Largura do botão
    height: "22px", // Altura do botão
    fontSize: "10px", // Tamanho da fonte
    marginRight: "20px", // Espaço à direita
  }}
>
  Editar
</Button>
<Button
  variant="contained"
  color="secondary"
  onClick={() => handleDelete(invoice.id)}
  style={{
    width: "50px", // Largura do botão
    height: "22px", // Altura do botão
    fontSize: "10px", // Tamanho da fonte
    marginTop: "10px", // Margem superior
  }}
>
  Deletar
</Button>

</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table> 
      
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <div className={classes.modal}>
          <Typography variant="h6" gutterBottom>
            Editar Fatura
          </Typography>
          <form>
            <TextField
              label="Nome do Plano"
              name="detail"
              value={currentInvoice?.detail || ""}
              onChange={handleModalChange}
              className={classes.field}
              fullWidth
            />
            <TextField
              label="Data de Vencimento"
              type="datetime-local"
              name="dueDate"
              value={currentInvoice?.dueDate || ""}
              onChange={handleModalChange}
              className={classes.field}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              label="Valor (R$)"
              type="number"
              name="value"
              value={currentInvoice?.value || ""}
              onChange={handleModalChange}
              className={classes.field}
              fullWidth
            />
            <TextField
              label="Conexões"
              type="number"
              name="connections"
              value={currentInvoice?.connections || ""}
              onChange={handleModalChange}
              className={classes.field}
              fullWidth
            />
           <TextField
             label="Link da Fatura"
             name="linkInvoice"
             value={currentInvoice?.linkInvoice || ""}
             onChange={handleModalChange}
             className={classes.field}
             fullWidth
           />

            <FormControl className={classes.field} fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={currentInvoice?.status || ""}
                onChange={handleModalChange}
              >
                <MenuItem value="Pago">Pago</MenuItem>
                <MenuItem value="Pendente">Pendente</MenuItem>
                <MenuItem value="Vencido">Vencido</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalSave}
            >
              Salvar
            </Button>
            <Button onClick={handleModalClose} style={{ marginLeft: "10px" }}>
              Cancelar
            </Button>
          </form>
        </div>
      </Modal>
    </Paper>
  );
};

export default GenerateInvoice;
